import React, { useRef, useEffect } from 'react';
import './styles.scss';
import { inject, observer } from 'mobx-react';
import { useSwipeable } from 'react-swipeable';
import { useWindowWidth } from '@react-hook/window-size';
import Img from 'gatsby-image';

export const Carousel = inject('store')(observer((props) => {
    const ref = useRef(null);
    if (typeof window !== `undefined`) {
        const [width] = useWindowWidth(); // eslint-disable-line no-unused-vars
    }

    useEffect(() => {
        if (ref.current.scrollLeft === 0) {
            return;
        }

        const closestImage = Array.prototype.reduceRight.call(
            ref.current.children,
            ((accumulator, currentValue) => 
                Math.abs(accumulator.offsetLeft - ref.current.scrollLeft) < Math.abs(currentValue.offsetLeft - ref.current.scrollLeft) ? accumulator : currentValue),
            ref.current.children[ref.current.children.length - 1]
        );

        ref.current.scrollLeft = closestImage.offsetLeft;
    });

    const swipeHandler = useSwipeable({
        onSwipedRight: () => {
            props.store.scrollLeft();
        },
        onSwipedLeft: () => {
            props.store.scrollRight();
        }
    });

    const oldRef = swipeHandler.ref;

    swipeHandler.ref = (el) => {
        ref.current = el;
        oldRef(el);
    };

    const scrollHorizontally = (e) => {
        if (props.store.selectedImage) {
            return;
        }
        const scrollAmount = props.store.screenType === 'mobile' ? ref.current.clientWidth / 3 : ref.current.clientWidth;
        if (e.deltaY > 0) {
            ref.current.scrollLeft += scrollAmount;
            props.store.scrollRight();
        } else {
            ref.current.scrollLeft -= scrollAmount;
            props.store.scrollLeft();
        }
    }

    const openPreview = (image, event) => {
        event.preventDefault();
        props.store.showImageView(image);
        return false;
    }

    return (
        <main className="carousel" onWheel={scrollHorizontally} {...swipeHandler}>
            {props.images.filter(image => !!image.src).map(image => (
            <figure key={`${image.title}${image.year}`} className={props.store.selectedImage?.src?.id === image.src.id ? 'selected' : null}>
                <a href={image.src.childImageSharp.fluid.src} onClick={(e) => openPreview(image, e)}>
                    <Img fluid={image.src.childImageSharp.fluid} alt={image.title} imgStyle={{ objectPosition: 'top left' }} />
                    <figcaption title={image.title}>{image.title}</figcaption><i className="icon-link" />
                </a>
            </figure>
            ))}
        </main>
    );
}));
