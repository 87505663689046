import React, { useRef } from 'react';
import { observer, inject } from 'mobx-react';

import './styles.scss';

export const ImagePreview = inject('store')(observer(({ store }) => {
    const ref = useRef(null);

    return (
        <div className="lightbox">
            <img
                ref={ref}
                src={store.selectedImage?.src.childImageSharp.fluid.src}
                alt={store.selectedImage?.title || 'Image Preview'}
            />
        </div>
    );
}));
